import React from 'react';
import ReactDOM from 'react-dom';

import 'assets/scss/index.scss';

import loader from 'assets/images/loader.svg';
const App = React.lazy(() => import('components/App/default')); // Lazy-loaded
const Preloader: React.FC = () => (
  <div className="page-loader">
    <h2>Loading</h2>
    <img src={loader} alt="page loader" />
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<Preloader />}>
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);
